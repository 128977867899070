<template>
  <b-row>
    <b-col sm="12">
      <b-card>
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0" v-if="parameters.remitFromOrder">Remitos desde Pedidos</h4>
            <h4 id="traffic" class="card-title mb-0" v-if="parameters.remitFromSale">Remitos desde Ventas</h4>
            
            <div class="small text-muted">Crear y administrar todos los remitos</div>
          </b-col>
          
          <b-col sm="5">
            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>

      <b-card id="list-remits" v-if="table.mostrarFiltros">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="10">
            <b-row>
              <b-col md="6" class="pb-1">
                <b-row>
                  <b-col md="6" class="pr-0">
                    <b-form-input type="date" size="md" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
                  </b-col>
                  <b-col md="6" class="pl-0">
                    <b-form-input type="date" size="md" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12">
                <b-row>
                  <b-col sm="3">
                    <v-select :options="arr.filters.customers" v-model="filters.customers" placeholder="Clientes" :multiple="true" :select-on-tab="true"></v-select>
                  </b-col>  
                  <b-col sm="3">
                    <v-select :options="arr.filters.points_sales" v-model="filters.points_sales" placeholder="Punto de Venta" :multiple="false" :select-on-tab="true"></v-select>
                  </b-col> 
                  <b-col sm="3" v-if="parameters.remitFromOrder">
                    <v-select :options="arr.filters.invoiced" v-model="filters.invoiced" placeholder="Facturado" :multiple="false" :select-on-tab="true"></v-select>
                  </b-col>
                  <b-col sm="2">
                    <b-button variant="outline-dark" @click="filterRemits()" size="sm">
                      <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                      Filtrar
                    </b-button>
                  </b-col>
                </b-row>
              </b-col>              
            </b-row>
          </b-col>
          <b-col sm="2">
            <b-link>
              <export-excel
                  class = 'pull-right'
                  :data = "arr.export"
                  worksheet = "Listado de Remitos"
                  name = "list-remits.xls">
                  Exportar Datos
              </export-excel>            
            </b-link>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="8" class="pr-0" id="view_list_remits">
          <b-card>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" 
                                  placeholder="Ingrese su busqueda para filtrar la grilla" 
                                  v-model="table.filter"
                                  size="sm">
                    </b-form-input>              
                  </b-input-group>
                </b-form-group>            
              </b-col>
              <b-col>
                <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Remito">
                  <i class="fa fa-plus"></i> Agregar
                </b-button>                
              </b-col>
              <b-col sm="12">
                <b-table class="mb-0 table-remits-custom"
                        ref="table"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="table.items"
                        :fields="table.fields"
                        :filter="table.filter"
                        :current-page="table.currentPage"
                        :per-page="table.perPage"
                        selectable
                        select-mode="single"
                        @row-selected="onRowSelected"  
                        :busy="table.isBusy"                      
                        v-if="table.items.length || table.isBusy">                       
                    
                    <template v-slot:table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"                    
                        :key="field.key"
                        :style="{ width: field.width }"
                      >
                    </template> 

                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                      </div>
                    </template>

                    <template v-slot:cell(id)="data">
                      <div v-if="data.item.type_voucher && data.item.point_sale">
                        {{data.item.type_voucher.name}} <br>
                        {{data.item.point_sale.point_sale.toString().padStart(4,'0') + '-' + data.item.number.toString().padStart(8,'0')}}
                      </div>
                    </template>
                    
                    <template v-slot:cell(date)="data">
                      {{moment(data.item.date).format('DD/MM/YYYY')}}
                    </template>

                    <template v-slot:cell(staff_id)="data">                      
                      <span v-if="data.item.staff_id" v-b-tooltip.hover :title="'STAFF: ' + data.item.staff.name"> 
                        <b-avatar :src="data.item.staff.image"
                                  v-if="data.item.staff.image">
                        </b-avatar>
                        <b-avatar v-else                                                                         
                                  variant="dark">
                        </b-avatar>              
                      </span>               
                    </template>
                    
                    <template v-slot:cell(customers_id)="data">  
                      <div v-if="data.item.customer">            
                        <b-avatar :src="data.item.customer.image"
                                  v-if="data.item.customer.image">
                        </b-avatar>
                        <b-avatar v-else 
                                  icon="building"                                            
                                  variant="dark">
                        </b-avatar>                                                     
                        &nbsp;<b>{{data.item.customer.name}}</b>   
                        
                        <b-icon icon="circle-fill" v-if="!data.item.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                                       
                      </div>
                    </template>

                    <template v-slot:cell(observations)="data">
                      <div v-if="data.item.observations" v-b-tooltip.hover :title="data.item.observations">
                        <b-icon icon="chat-square-text"></b-icon>
                      </div>                                              
                    </template>
                    
                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right 
                                  text="Acción" 
                                  size="sm" 
                                  variant="outline-dark" 
                                  class="pull-right" 
                                  @show="setConfigTableRemitsShow"
                                  @hide="setConfigTableRemitsHide">
                        
                        <b-dropdown-item @click="sendPrinter(data.item)">
                          <b-icon icon="printer"></b-icon> Imprimir
                        </b-dropdown-item>            

                        <b-dropdown-item @click="generateInvoice(data.item)" v-if="!data.item.sale_id && parameters.remitFromOrder">
                          <b-icon icon="file-earmark-ruled"></b-icon> Facturar
                        </b-dropdown-item>                                                  
                                                
                        <b-dropdown-header>Acciones</b-dropdown-header>
                        <b-dropdown-item @click="remove(data.item)">
                          <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="4" id="contain_details_items">
          <div id="details_items">
            <b-card>            
              <b-row>              
                <b-col md="12">
                  
                  <!-- COMPROBANTE SELECCIONADO -->
                  <b-row class="mb-3">
                    <b-col md="8">
                      <div class="crud-remits-title" v-if="itemSelected">
                        <div class="crud-remits-title">
                          <span class="crud-remits-code" v-if="itemSelected.type_voucher && itemSelected.point_sale">                          
                            {{itemSelected.type_voucher.name}} {{itemSelected.point_sale.point_sale.toString().padStart(4,'0') + '-' + itemSelected.number.toString().padStart(8,'0')}}                          
                          </span><br>
                          <span v-if="itemSelected.customer">
                            {{this.itemSelected.customer.name}}                          
                          </span>                          
                        </div>
                      </div>
                    </b-col>
                    <b-col md="4">
                      <b-row>
                        <b-col md="12">
                          <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="hideSub()" v-b-tooltip.hover title="Ocultar Detalle">
                            <i class="fa fa-window-close"></i>
                          </b-button>
                        </b-col>                      
                      </b-row>                   
                    </b-col>
                  </b-row>

                  <!-- DETALLE -->                
                  <b-row>
                    <b-col md="12">     
                      <b-link v-if="tableSub.items.length" 
                              @click="openDetail()"
                              class="pull-right">
                        Ver detalle completo
                      </b-link>                                     
                      <b-table  class="mb-0 table-remits-sub"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="tableSub.items"
                                :fields="tableSub.fields"                            
                                v-if="tableSub.items.length">   
                        
                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template>                         

                        <template v-slot:cell(quantity)="row"> 
                          {{row.item.quantity}}
                          <span v-if="row.item.unit_measurement">{{row.item.unit_measurement.reference}}</span>
                        </template>

                        <template v-slot:cell(product)="row">
                          <div v-html="getProductName(row.item)"></div>
                        </template>

                      </b-table>                      
                      <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                    </b-col>                    
                  </b-row>
                </b-col>
              </b-row>
            </b-card>           
          </div>
        </b-col>
      </b-row>    

      <b-row>
        <b-col lg="8" class="pr-0" id="view_footer_remits">  
          <b-card>
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>

              <b-col>
                <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"
                                @input="filterRemits()" />
                </nav>
              </b-col>          
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!--
      ###########################
      ######### PRINTER #########
      ###########################
      -->
      <Printer v-if="crud.print.id" 
              :reference="crud.print.reference" 
              :registerID="crud.print.id"
              :key="crud.print.key"/>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close      
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <form-wizard  title=""
                      subtitle=""
                      nextButtonText="Siguiente"
                      backButtonText="Atrás"
                      finishButtonText="Guardar"
                      color="#2f353a"
                      shape="tab"
                      :start-index="wizard.startIndex"
                      @on-complete="save"
                      class="crud-remits-wizard">

          <tab-content title="General" icon="fa fa-edit" :before-change="saleValidDetailGeneral">          
            <b-row>      
              <b-col md="4">
                <b-form-group label="Fecha">
                  <b-form-datepicker size="sm" v-model="crud.form.date" placeholder="Fecha" local="es"></b-form-datepicker>                      
                </b-form-group>            
              </b-col>
            </b-row>              
            <b-row> 
              <b-col md="5">
                <FindObject render="search"
                            type="customers" 
                            @select-object="loadCustomers($event)" 
                            :valueID="crud.form.customers_id"
                            :where="conditionCustomersActive"
                            :key="crud.form.customers_id"
                            :disabled="crud.form.id>0 || remitFrom" />                 
              </b-col>    
              <b-col md="3">
                <FindObject render="search"
                            type="erpPointsSales" 
                            @select-object="loadPointsSales($event)" 
                            :valueID="crud.form.points_sales_id"
                            :key="crud.form.points_sales_id"
                            :disabled="crud.form.id>0 || remitFrom"  />                 
              </b-col>
            </b-row>
          </tab-content>

          <tab-content title="Items" icon="fa fa-list" :before-change="saleValidDetailItems">          
            <b-row class="mb-3" v-if="!parameters.remitMultipleItems">
              <b-col lg="6" md="6" sm="6" v-if="crud.formVouchersPending.length">

                <div v-if="parameters.remitFromOrder">
                  <b-form-group label="Pedidos">
                    <b-form-select v-model="crud.form.reference_voucher_id" :options="crud.formVouchersPending" size="sm" @input="filtersItems" :disabled="remitFrom"></b-form-select>
                  </b-form-group>
                </div>
                <div v-if="parameters.remitFromSale">
                  <b-form-group label="Comprobante de Venta">
                    <b-form-select v-model="crud.form.reference_voucher_id" :options="crud.formVouchersPending" size="sm" @input="filtersItems"></b-form-select>
                  </b-form-group>
                </div>                

              </b-col>
            </b-row>
            <b-row>
              <b-col lg="12" md="12" sm="12">
                <b-overlay :show="overlay.remits" rounded="sm">
                  <b-table-simple hover small caption-top responsive v-if="arr.pending.length" class="crud-remit-table-items">
                    <b-thead head-variant="dark">                    
                      <b-tr>
                        <b-th width="5%" class="text-left">Remitir</b-th>
                        <b-th width="20%" class="text-left">Comprobante</b-th>
                        <b-th width="10%" class="text-left">Código</b-th>
                        <b-th width="30%" class="text-left">Descripción</b-th>
                        <b-th width="5%" class="text-center">Est.Vto.</b-th>
                        <b-th width="10%" class="text-center">Cantidad</b-th>
                        <b-th width="10%" class="text-center">Remitido</b-th>
                        <b-th width="10%" class="text-center">A Remitir</b-th>                                            
                      </b-tr>
                    </b-thead>      
                    <b-tbody v-for="(item, index) in arr.pending" :key="item.id">                         
                      <b-tr :key="itemForceUpdate" v-if="!item.services_id && !item.concepts_id && crud.formPending[index]" :variant="crud.formPending[index].vto_status_alert">
                        <b-td class="text-left align-middler">
                          <b-form-checkbox v-model="crud.formPending[index].check"
                                            @change="validCheck(index)"
                                            v-if="!(crud.formPending[index].vto_status == 'VENCIDO')"
                                            switch
                                            size="sm"
                                            class="pull-left">
                          </b-form-checkbox> 
                        </b-td>
                        <b-td class="text-left align-middle">
                          {{crud.formPending[index].voucher}}
                        </b-td>
                        <b-td class="text-left align-middle">
                          <div v-html="getProductCode(item)"></div>                        
                        </b-td>
                        <b-td class="text-left align-middle">                        
                          <div v-html="getProductName(item)"></div>
                        </b-td>
                        <b-td class="text-center align-middle">                        
                          <div v-if="crud.formPending[index].vto">
                            {{crud.formPending[index].vto_status}}
                          </div>
                        </b-td>                        
                        <b-td class="text-center align-middle">
                          {{item.quantity}}
                          <span v-if="item.unit_measurement">{{item.unit_measurement.reference}}</span>
                        </b-td>                      
                        <b-td class="text-center align-middle">
                          {{item.quantity_remit}}
                          <span v-if="item.unit_measurement">{{item.unit_measurement.reference}}</span>
                        </b-td>                      
                        <b-td class="text-center align-middle">
                          <b-form-input type="number"
                                        size="sm"
                                        step="0.01"
                                        min="0"                                      
                                        @change="validQuantity(index)"
                                        v-model="crud.formPending[index].quantity"                                      
                                        placeholder="Ingresar cantidad"
                                        class="crud-remit-item-input"
                                        v-if="crud.formPending[index].check">
                          </b-form-input>                        
                        </b-td>                      
                      </b-tr>                    
                    </b-tbody>                              
                  </b-table-simple>   
                  <div v-else>
                    <b-alert show variant="info" v-if="crud.formVouchersPending.length">
                      Seleccione un comproabnte
                    </b-alert>                
                    <b-alert show variant="warning" v-else>
                      No hay productos para remitir.
                    </b-alert>                                    
                  </div>
                </b-overlay>
              </b-col> 
            </b-row>
          </tab-content>

          <tab-content title="Observaciones" icon="fa fa-flag-checkered">          
            <b-row>
              <b-col md="12">
                <b-form-group label="Observaciones">
                  <b-form-textarea                
                    v-model="crud.form.observations"
                    placeholder="Ingresar las observaciones..."
                    rows="3"
                    max-rows="6"
                    lazy
                  ></b-form-textarea>
                </b-form-group>
              </b-col>         
            </b-row>
          </tab-content>

        </form-wizard>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="cancel()">Cancelar</b-button>              
        </div>                
      </b-modal>

      <!-- CRUD DETAILS -->
      <b-modal v-model="modal.detail.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.detail.title}}
        </div>

        <b-row>
          <b-col sm="12">                     
            <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">
              <b-tab title="General">
                <table class="table table-hover table-sm" v-if="itemSelected">
                  <tbody>
                    <tr>
                      <td class="crud-remits-title-table-custom align-middle">ID</td>
                      <td class="crud-remits-value-table-custom align-middle">
                        <b>#{{this.itemSelected.id}}</b>
                      </td>
                    </tr>                     
                    <tr v-if="itemSelected.type_voucher && itemSelected.point_sale">
                      <td class="crud-remits-title-table-custom align-middle">Comprobante</td>                      
                      <td class="crud-remits-value-table-custom align-middle">
                        <b>{{this.itemSelected.type_voucher.name + ' '  + this.itemSelected.point_sale.point_sale.toString().padStart(4,'0') + '-' + this.itemSelected.number.toString().padStart(8,'0')}}</b>
                      </td>
                    </tr>        
                    <tr v-if="itemSelected.sale && itemSelected.sale.type_voucher && itemSelected.sale.point_sale">
                      <td class="crud-remits-title-table-custom align-middle">Factura Vinculada</td>                      
                      <td class="crud-remits-value-table-custom align-middle">
                        {{this.itemSelected.sale.type_voucher.name + ' '  + this.itemSelected.sale.point_sale.point_sale.toString().padStart(4,'0') + '-' + this.itemSelected.sale.number.toString().padStart(8,'0')}}
                      </td>
                    </tr>                                                                           
                    <tr v-if="itemSelected.staff || itemSelected.seller">
                      <td class="crud-remits-title-table-custom align-middle">Emisor</td>                      
                      <td class="crud-remits-value-table-custom align-middle">
                        <div v-if="itemSelected.staff">
                          {{itemSelected.staff.name}}
                        </div> 
                      </td>
                    </tr>                                                             
                    <tr>
                      <td class="crud-remits-title-table-custom align-middle">Fecha</td>
                      <td class="crud-remits-value-table-custom align-middle">
                        {{moment(this.itemSelected.date).format('DD MMMM YYYY')}}
                      </td>
                    </tr>                                          
                    <tr v-if="itemSelected.point_sale">
                      <td class="crud-remits-title-table-custom align-middle">Punto de Venta</td>
                      <td class="crud-remits-value-table-custom align-middle">
                        {{this.itemSelected.point_sale.point_sale}} - {{this.itemSelected.point_sale.name}}                          
                      </td>
                    </tr>                                           
                    <tr v-if="itemSelected.customer">
                      <td class="crud-remits-title-table-custom align-middle">Cliente</td>
                      <td class="crud-remits-value-table-custom align-middle">
                        {{this.itemSelected.customer.name}}                        
                      </td>
                    </tr>                        
                    <tr v-if="itemSelected.observations">
                      <td class="crud-remits-title-table-custom align-middle">Observaciones</td>
                      <td class="crud-remits-value-table-custom align-middle">                        
                        {{this.itemSelected.observations}}
                      </td>
                    </tr>                              
                  </tbody>
                </table>
              </b-tab>              
              <b-tab title="Items">                
                <b-table class="mb-0 table-full-detail-remits"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableDetail.items"
                        :fields="tableDetail.fields"
                        v-if="tableDetail.items.length">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>           

                  <template v-slot:cell(code)="data">                           
                    <div v-html="getProductCode(data.item)"></div>
                  </template>

                  <template v-slot:cell(product)="data">                           
                    <div v-html="getProductName(data.item)"></div>
                  </template>                  

                  <template v-slot:cell(quantity)="data">   
                    {{data.item.quantity}}                        
                    <span v-if="data.item.unit_measurement">{{data.item.unit_measurement.reference}}</span>
                  </template>                  
                  
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.detail.active=false">Cancelar</b-button>                   
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Param from '@/config/parameters'
  import FindObject from '@/components/inc/find/findObject'  
  import Printer from '@/components/inc/printer/printer'  
  import moment from 'moment'
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'

  export default {
    components: {
      FindObject,
      Printer,      
      FormWizard,
      TabContent,      
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.REMITOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'remits',
          elements: {}
        },
        table : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,          
        },     
        tableSub : {
          items: [],
          fields: []
        },            
        tableDetail : {
          items: [],
          fields: []
        },            
        crud: {
          form: {
            id: 0,
            date: '',
            date_expiration: '',              
            types_vouchers_id: 0,
            types_vouchers: null,
            letter: '',
            number: 0,
            points_sales_id: 0,
            points_sales: null,              
            observations: '',            
            staff_id: 0,
            customers_id: 0,
            customers: null,                            
            detail: [],
            selectTypeVoucher: 'remito',
            reference_voucher_id: 0,
            orders: null,
            orders_id: 0,              
          },    
          formVouchersPending: [],
          formPending: [],       
          print: {
            reference: '',
            id: 0,
            key: 0,
          }               
        },
        modal: {
          form: {
            active: false,
            title: '',
          },
          detail: {
            active: false, 
            title: '',
          },            
        },          
        arr: {
          remits: [],  
          pending: [],
          export: [],           
          filters : {
            customers: [],            
            points_sales: [],
            invoiced: [
              {code: true, label: 'Facturado'},
              {code: false, label: 'No Facturado'}
            ]
          },                              
        },           
        filters: {
          date_start: '',
          date_end: '',
          customers: null,
          points_sales: null,
          invoiced: null
        },     
        itemSelected: null,             
        itemForceUpdate: 0,
        parameters: {
          remitFromOrder: Helper.hasParametersAccess(Param.P26),
          remitFromSale: Helper.hasParametersAccess(Param.P27),
          remitMultipleItems: Helper.hasParametersAccess(Param.P28),
        },            
        wizard: {
          startIndex: 0,
        },
        overlay: {
          remits: false,
        }
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },     
    mounted() {   
      this.loadFieldTable()
      this.filterLoad()   
      this.filterRemits()              
      this.loadStyleConfig()

      this.crud.form.orders_id = this.$route.params.ordersID
      if(this.crud.form.orders_id) {
        this.remitsFromOrders()
      }        
    },    
    computed: {      
      // CONDITIONS SELECT
      conditionCustomersActive(){
        return [{field: 'active', condition: true}];
      },  

      // REMIT DESDE
      remitFrom() {
        var status = false
        if(this.crud.form.orders_id) {
          status = true
        }        
        return status
      }      
    },
    watch: {        
      'crud.form.customers_id': function (newQuestion, oldQuestion) {         
        this.arr.pending = []
        this.crud.form.reference_voucher_id = 0
        this.searchVoucherByCustomer()
      },
      'crud.form.points_sales_id': function (newQuestion, oldQuestion) {                
        this.arr.pending = []
        this.crud.form.reference_voucher_id = 0
        this.searchVoucherByCustomer()
      },
    },    
    methods: {
      // CONFIGURACION
      loadStyleConfig () {        
        var view_list_remits = document.getElementById('view_list_remits')
        var view_footer_remits = document.getElementById('view_footer_remits')

        var padre = document.getElementById('contain_details_items')
        var hijo = document.getElementById('details_items')  
            
        hijo.style.width = padre.clientWidth - 45 + 'px'

        if(window.innerWidth < 991) {
          hijo.style.width = '100%'
          hijo.style.position = "relative"
          hijo.style.right = 'auto'

          view_list_remits.classList.remove('pr-0')
          view_footer_remits.classList.remove('pr-0')
        } else {          
          view_list_remits.classList.add('pr-0')
          view_footer_remits.classList.add('pr-0')
        }        
      },
      loadFieldTable () {
        // TABLE COMPROBANTES
        this.table.fields.push({key: 'id', label: 'Nº', sortable: true, class:"align-middle text-center", width:"120px"})
        this.table.fields.push({key: 'date', label: 'Fecha', class:"align-middle text-center", width:"80px"})                
        this.table.fields.push({key: 'customers_id', label: 'Cliente', class:"align-middle", width:"200px"})                      
        this.table.fields.push({key: 'staff_id', label: 'Staff', class:"align-middle text-center", width:"60px"})        
        this.table.fields.push({key: 'observations', label: '', class:"align-middle text-center", width:"25px"})                        
        this.table.fields.push({key: 'f_action', label:'', class:"align-middle", width:"60px"})

        // TABLE DETALLE
        this.tableSub.fields.push({key: 'quantity', label: 'Cantidad', class:"align-middle", width:"50px"})                
        this.tableSub.fields.push({key: 'product', label: 'Producto', class:"align-middle", width:"150px"})                

        // TABLE DETALLE COMPLETO        
        this.tableDetail.fields.push({key: 'code', label: 'Código', class:"align-middle", width:"50px"})                
        this.tableDetail.fields.push({key: 'product', label: 'Producto', class:"align-middle", width:"200px"})                
        this.tableDetail.fields.push({key: 'quantity', label: 'Cant', class:"align-middle text-center", width:"60px"})                                 
      },      
      getRowCount(items) {
        return items.length
      },
      onRowSelected(item) {         
        this.arr.remits.forEach((element, index) => {
          this.table.items[index]._showDetails = false
          if(item.length){
            if(element.id == item[0].id) {  
              this.openSub(element)                             
            }
          }
        })
      },   
      setConfigTableRemitsShow() {
        var arrTable = document.getElementsByClassName('table-remits-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {          
          arrTable[i].style.minHeight = '150px'
        }        
      },
      setConfigTableRemitsHide() {
        var arrTable = document.getElementsByClassName('table-remits-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },  
      setConfigTableSubShow() {
        var arrTable = document.getElementsByClassName('table-remits-sub')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = '210px'
        }        
      },
      setConfigTableSubHide() {
        var arrTable = document.getElementsByClassName('table-remits-sub')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },  

      // SELECT
      loadCustomers(object) {                
        if(object){          
          this.crud.form.customers = object
          this.crud.form.customers_id = object.id   
          
          if(object.data_fiscal) {
            if(object.data_fiscal.points_sales_id) {
              if(!this.crud.form.points_sales_id) {
                this.crud.form.points_sales = object.data_fiscal.points_sales
                this.crud.form.points_sales_id = object.data_fiscal.points_sales_id
              }
            }
          }
        } else {
          this.crud.form.customers = null
          this.crud.form.customers_id = 0
        }
      }, 
      loadPointsSales(object) {                
        if(object){
          this.crud.form.points_sales = object
          this.crud.form.points_sales_id = object.id             
        } else {
          this.crud.form.points_sales = null
          this.crud.form.points_sales_id = 0
        }
      },
      prepareExport(data) {        
        this.arr.export = []
        data.forEach(element => {
            var customersID = 0
            var customersCode = ""
            var customersName = ""
            var voucherReference = ""

            if(element.customer) {
              customersID = element.customer.id
              customersCode = element.customer.code
              customersName = element.customer.name            
            }
            if(element.type_voucher) {            
              voucherReference = element.type_voucher.name + ' ' + element.point_sale.point_sale.toString().padStart(4,'0') + '-' + element.number.toString().padStart(8,'0')
            }                 

            this.arr.export.push({
              id: element.id,
              date: element.date,      
              voucherReference: voucherReference,        
              customersID: customersID,
              customersCode: customersCode,
              customersName: customersName,                   
              observations: element.observations,
              productID: 0,
              productCode: '',
              productName: '',
              productCompoundID: 0,
              productCompoundName: "",                                          
              quantity: 0,
              unitMeasurementID: 0,
              unitMeasurementName: "", 
            })

            element.details.forEach(element1 => {
              var productID = 0
              var productCode = ""
              var productName = ""
              var productCompoundID = 0
              var productCompoundName = ""     
              var unitMeasurementID = 0
              var unitMeasurementName = ""                

              var item = null
              if(element1.order_detail_id) {
                item = element1.orders_detail
              } 
              if(element1.sale_detail_id) {
                item = element1.sales_detail
              }          

              if(item.products_id) {
                productID = item.product.id
                productCode = item.product.code
                productName = item.product.name            
              }

              if(item.products_colors_id) {
                productID = item.products_color.id
                productCode = item.products_color.code
                productName = item.products_color.name + " (" + item.products_color.color.name + ")"
              }            

              if(item.products_waist_id) {
                productID = item.products_waist.id
                productCode = item.products_waist.code
                productName = item.products_waist.name + " (" + item.products_waist.waist.name + ")"
              }            

              if(item.products_color_waist_id) {
                productID = item.products_color_waist.id
                productCode = item.products_color_waist.code
                productName = item.products_color_waist.name + " (" + item.products_color_waist.color.name + " - " + item.products_color_waist.waist.name + ")"
              }       

              if(item.products_compound_id) {
                productCompoundID = item.products_compound.id
                productCompoundName = item.products_compound.name            
              }    

              if(element1.unit_measurement_id) {
                unitMeasurementID = element1.unit_measurement.id
                unitMeasurementName = element1.unit_measurement.name            
              }                     

              this.arr.export.push({
                productID: productID,
                productCode: productCode,
                productName: productName,
                productCompoundID: productCompoundID,
                productCompoundName: productCompoundName,                                          
                quantity: element1.quantity.replace('.',','),
                unitMeasurementID: unitMeasurementID,
                unitMeasurementName: unitMeasurementName,                                
              })
            })
          });
      },

      // ABM MAIN
      add() {
        this.crud.form.id = 0           
        this.crud.form.date = moment().format('YYYY-MM-DD')        
        this.crud.form.observations = ''
        this.crud.form.staff_id = Helper.getStaffId()
        this.crud.form.customers_id = 0
        this.crud.form.customers = null   
        this.crud.form.points_sales_id = 0
        this.crud.form.points_sales = null
        this.crud.form.detail = []   
        
        this.crud.formPending = []
        this.arr.pending = []
        this.crud.form.reference_voucher_id = 0

        this.modal.form.title = "Nuevo Remito"
        this.modal.form.active = true
      },            
      remove(item) {
        this.crud.form.id = item.id
        
        var voucher = "ID #" + item.id
        if(item.type_voucher && item.point_sale) {
          voucher = item.type_voucher.name + ' ' + item.point_sale.point_sale.toString().padStart(4,'0') + '-' + item.number.toString().padStart(8,'0')
        }        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el remito ' + voucher + '?', {
          title: 'Borrar Remito',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarRemito(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filterRemits()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },      
      save() {
        this.$bvModal.msgBoxConfirm('¿Desea continuar con la generación del remito?', {
          title: 'Generar Remito',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GENERAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {          
            let loader = this.$loading.show();
              
            var result = serviceAPI.agregarRemito(this.crud.form);

            result.then((response) => {
              this.modal.form.active = false

              // blanqueo remit from
              this.crud.form.orders_id = 0
              this.crud.form.orders = null
              this.wizard.startIndex = 0

              loader.hide()
              this.filterRemits()
              this.$awn.success("Remito generado con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })              
      },          

      // DETALLE REMITO      
      saleValidDetailGeneral() {
        if(this.crud.form.customers_id && this.crud.form.points_sales_id) {

          if(this.parameters.remitMultipleItems) {
            this.obtenerPendienteRemitirByCliente(this.crud.form.customers_id,0,this.crud.form.points_sales_id)  
          } else {
            this.obtenerVouchersByCliente(this.crud.form.customers_id,this.crud.form.points_sales_id)  
          }
          
          return true;
        } else {
          if(!this.crud.form.customers_id) {
            this.$awn.alert("No se cargó el cliente");
            return false;
          }
          if(!this.crud.form.points_sales_id) {
            this.$awn.alert("No se cargó el punto de venta");
            return false;
          }
        }        
      },      
      obtenerVouchersByCliente(customer_id=0, points_sales_id=0) {
        this.overlay.remits = true
        this.crud.formVouchersPending = []

        var result = serviceAPI.filtrarVoucherPendienteRemitir({'customers_id':customer_id, 'points_sales_id':points_sales_id})        
        result.then((response) => {
          var data = response.data    
          this.overlay.remits = data.length > 0

          if(data) {
            data.forEach(element => {              
              if(this.parameters.remitFromOrder) {
                this.crud.formVouchersPending.push({
                  value: element.id,
                  text: 'Pedido Nº: ' + element.id + ' | ' + moment(element.date).format('DD/MM/YYYY'),
                })
              } 

              if(this.parameters.remitFromSale) {
                this.crud.formVouchersPending.push({
                  value: element.id,
                  text: element.type_voucher.name + ' ' + element.point_sale.point_sale.toString().padStart(4,'0') + '-' + element.number.toString().padStart(8,'0') + ' | ' + moment(element.date).format('DD/MM/YYYY'),                  
                })                
              }
            });
          }  
                    
          this.overlay.remits = false
        })  
      },
      filtersItems(vouchers_id) {
        this.obtenerPendienteRemitirByCliente(this.crud.form.customers_id, vouchers_id, this.crud.form.points_sales_id)          
      },      
      obtenerPendienteRemitirByCliente(customer_id=0, vouchers_id=0, points_sales_id=0) {
        this.overlay.remits = true
        this.crud.formPending = []

        var result = serviceAPI.filtrarPendienteRemitir({'customers_id':customer_id, 'vouchers_id':vouchers_id, 'points_sales_id': points_sales_id})        
        result.then((response) => {
          var data = response.data 
          this.overlay.remits = data.length > 0
          
          this.arr.pending = data   
          this.arr.pending.forEach(element => {
            if(this.parameters.remitFromOrder) {
              this.crud.formPending.push({              
                check: false,         
                voucher: 'ORDEN DE PEDIDO ' + element.order.points_sales.point_sale.toString().padStart(4,'0') + '-' + element.order.id.toString().padStart(8,'0'),
                quantity: parseFloat(element.quantity) - parseFloat(element.quantity_remit),
                maxQuantity: parseFloat(element.quantity) - parseFloat(element.quantity_remit),
                order_detail_id: element.id,
                description: element.referenceProduct,
                vto: 0,
                vto_status: 'LIBRE',
                vto_status_alert: '',
                unit_measurement_id: element.unit_measurement_id,
              })
            }

            if(this.parameters.remitFromSale) {                              
              this.crud.formPending.push({              
                check: false, 
                voucher: element.sale.type_voucher.name + ' ' + element.sale.point_sale.point_sale.toString().padStart(4,'0') + '-' + element.sale.number.toString().padStart(8,'0'),             
                quantity: parseFloat(element.quantity) - parseFloat(element.quantity_remit),
                maxQuantity: parseFloat(element.quantity) - parseFloat(element.quantity_remit),
                sale_detail_id: element.id,
                description: element.description,
                vto: element.vto.days,
                vto_status: element.vto.status,
                vto_status_alert: element.vto.alert,
                unit_measurement_id: element.unit_measurement_id,
              })
            }            
          });

          this.overlay.remits = false
        })                   
      },
      validCheck(index) {
        if(!this.crud.formPending[index].check) {
          this.crud.formPending[index].quantity = this.crud.formPending[index].maxQuantity          
          this.forceUpdate()
        }
      },
      validQuantity(index){        
        if(this.crud.formPending[index].quantity=='') {
          this.crud.formPending[index].quantity = 0
          this.crud.formPending[index].check = false
        }

        if( parseFloat(this.crud.formPending[index].quantity) > parseFloat(this.crud.formPending[index].maxQuantity)) {          
          this.crud.formPending[index].quantity = this.crud.formPending[index].maxQuantity          
          this.forceUpdate()
        }

        if( parseFloat(this.crud.formPending[index].quantity) <= 0) {          
          this.crud.formPending[index].quantity = this.crud.formPending[index].maxQuantity          
          this.crud.formPending[index].check = false
          this.forceUpdate()
        }        
      },
      forceUpdate() {
        this.itemForceUpdate = this.itemForceUpdate + 1
      },
      saleValidDetailItems() {
        this.crud.form.detail = []

        if(this.crud.formPending.length) {
          this.crud.formPending.forEach(element => {                  
            if(element.check && parseFloat(element.quantity)>0) {

              if(this.parameters.remitFromOrder) {
                this.crud.form.detail.push({                
                  'quantity': element.quantity,
                  'unit_measurement_id': element.unit_measurement_id,
                  'order_detail_id': element.order_detail_id,
                  'description': element.description,
                })
              }

              if(this.parameters.remitFromSale) {
                this.crud.form.detail.push({                
                  'quantity': element.quantity,
                  'unit_measurement_id': element.unit_measurement_id,
                  'sale_detail_id': element.sale_detail_id,
                  'description': element.description,
                })              
              }
            }
          });
        }
        if(this.crud.form.detail.length) {
          return true;
        } else {
          this.$awn.alert("No se cargó ningún item");
          return false;
        }
      },      
      cancel() {
        this.$bvModal.msgBoxConfirm('¿Desea cancelar el remito?', {
          title: 'Cancelar el Remito',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            this.modal.form.active = false

            // blanqueo remit from
            this.crud.form.orders_id = 0
            this.crud.form.orders = null                 
            this.wizard.startIndex = 0
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })   
      },      

      // SELECT VOUCHER 
      searchVoucherByCustomer() {
        if(!this.crud.form.id) {
          if(this.crud.form.customers_id && this.crud.form.points_sales_id ) {
            var result = serviceAPI.obtenerVoucherByCustomer({
              'customers_id': this.crud.form.customers_id,
              'reference': this.crud.form.selectTypeVoucher,
            });
            result.then((response) => {    
              this.crud.form.types_vouchers_id = response.data[0].id							
              this.searchVoucher()                          
            })
            .catch(error => {
              this.$awn.alert(Error.showError(error));
            })   						           
          }
        } else {                                      
          if( this.crud.form.types_vouchers ) {                          
            this.modal.form.title = this.crud.form.types_vouchers.name
            this.modal.form.title = this.modal.form.title + ' ' + this.crud.form.points_sales.point_sale.toString().padStart(4,'0')
            this.modal.form.title = this.modal.form.title + '-' + this.crud.form.number.toString().padStart(8,'0')
          }                   
        }
      },			
      searchVoucher() {
        if(!this.crud.form.id) {
          if(this.crud.form.customers_id && this.crud.form.points_sales_id ) {
            var result = serviceAPI.obtenerVoucherById({
              'points_sales_id': this.crud.form.points_sales_id,
              'types_vouchers_id' : this.crud.form.types_vouchers_id,
            });
            result.then((response) => {                      
              this.crud.form.types_vouchers = response.data.type_voucher
              this.crud.form.letter = response.data.type_voucher.letter                    
              this.crud.form.number = response.data.number
          
              if( this.crud.form.types_vouchers ) {              
                this.modal.form.title = this.crud.form.types_vouchers.name
                this.modal.form.title = this.modal.form.title + ' ' + this.crud.form.points_sales.point_sale.toString().padStart(4,'0')
                this.modal.form.title = this.modal.form.title + '-' + this.crud.form.number.toString().padStart(8,'0')
              }                                
            })
          }
        } else {                                      
          if( this.crud.form.types_vouchers ) {                          
            this.modal.form.title = this.crud.form.types_vouchers.name          
            this.modal.form.title = this.modal.form.title + ' ' + this.crud.form.points_sales.point_sale.toString().padStart(4,'0')
            this.modal.form.title = this.modal.form.title + '-' + this.crud.form.number.toString().padStart(8,'0')
          }                   
        }
      },  

      // FILTER
      filterLoad() {   
        this.filterLoadCustomers()          
        this.filterLoadPointSale()                
        this.filters.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filters.date_end = moment().format('YYYY-MM-DD') 
      },
      filterLoadCustomers() {
        var result = serviceAPI.obtenerClientes()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.customers = []
          data.forEach(element => {            
            if(element.code) {
              this.arr.filters.customers.push({ code: element.id, label: element.code + ' | ' + element.name })            
            } else {
              this.arr.filters.customers.push({ code: element.id, label: element.name })            
            }            
          });          
        })   
      },
      filterLoadPointSale() {
        var result = serviceAPI.obtenerPuntosVentas()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.points_sales = []
          data.forEach(element => {                            
            this.arr.filters.points_sales.push({ code: element.id, label: element.name })            
          });          
        })   
      },       
      filterRemits(forceOpenSub=false) {
        this.table.isBusy = true
        var result = serviceAPI.filtrarRemitos(this.filters, this.table.currentPage)        
        result.then((response) => {          
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data
          this.arr.remits = data.data
          this.prepareExport(data.data)
          
          this.table.items.forEach(element => {            
            if(forceOpenSub) {
              if(element.id == this.itemSelected.id) {
                this.openSub(element)
              }              
            }                
          });

          if(this.table.rowSelected!=null) {            
            this.table.items[this.table.rowSelected]._showDetails = true                   
          }         

          if(this.$refs.table) {            
            this.$refs.table.$forceUpdate()
          }          
            
          this.table.isBusy = false
        })           
        .catch(error => {            
          this.table.isBusy = false  
          this.$awn.alert(ErrorToken.valid(error));
        })  
      },    

      // REMITS FROM ORDERS
      remitsFromOrders() {        
        var result = serviceAPI.mostrarPedido(this.crud.form.orders_id);
        result.then((response) => {                 
          var data = response.data   

          if(data.remitted) {
            this.$awn.alert("El pedido ya se encuentra remitido");
            return false            
          }

          this.wizard.startIndex = 1
          this.add()

          setTimeout(()=>{                                                                      
            this.crud.form.orders = data
            this.crud.form.customers_id = data.customers_id
            this.crud.form.customers = data.customer
            this.crud.form.points_sales_id = data.erp_points_sales_id
            this.crud.form.points_sales = data.points_sales            
                        
            this.saleValidDetailGeneral();
            setTimeout(()=>{
              this.crud.form.reference_voucher_id = data.id              
            },1000)

          },1000)          
        })
        .catch(error => {          
          // blanqueo remit from
          this.crud.form.orders_id = 0
          this.crud.form.orders = null
          this.wizard.startIndex = 0

          this.$awn.alert(Error.showError(error));
        })  
      },

      // COMPROBANTE DETAIL
      openSub(item) {                
        this.itemSelected = item        
        
        this.tableSub.items = item.details           
        this.loadStyleConfig()
      },    
      hideSub() {
        this.tableSub.items=[]
        this.itemSelected = null
      },               
      openDetail() {              
        this.tableDetail.items = this.itemSelected.details

        this.modal.detail.title = "Detalle del Remito"
        this.modal.detail.active = true
      }, 
      getProductCode(item) {
        if(item.orders_detail) {
          item = item.orders_detail
        } 
        
        if(item.sales_detail) {
          item = item.sales_detail
        }        

        var code = ''
        var prod = null

        if(item.code) {
          code = item.code
        }          

        if(item.products_id) {
          if(code) {
            return code
          } else {                      
            return item.product.code
          }
        }

        if(item.products_colors_id) {
          prod = item.products_color 
          if(code) {
            return code
          } else {
            return prod.code
          }           
        }

        if(item.products_waist_id) {
          prod = item.products_waist 
          if(code) {
            return code
          } else {
            return prod.code
          }                     
        }

        if(item.products_color_waist_id) {
          prod = item.products_color_waist 
          if(code) {
            return code
          } else {
            return prod.code
          }                     
        }

        if(item.products_compound_id) {
          prod = item.products_compound 
          if(code) {
            return code
          } else {
            return prod.code
          }   
        }        
      },      
      getProductName(item) {                    
        if(item.orders_detail) {
          item = item.orders_detail
        } 
        
        if(item.sales_detail) {
          item = item.sales_detail
        }        

        var name = ''
        var prod = null

        if(item.description) {
          name = item.description
        }          
        
        if(item.products_id) {
          if(name) {
            return name
          } else {
            return item.product.name
          }          
        }

        if(item.products_colors_id) {
          prod = item.products_color 
          if(name) {
            return name + ' (' + prod.color.name + ')'
          } else {
            return prod.name + ' (' + prod.color.name + ')'
          }                    
        }

        if(item.products_waist_id) {
          prod = item.products_waist           
          if(name) {
            return name + ' (' + prod.waist.name + ')'
          } else {
            return prod.name + ' (' + prod.waist.name + ')'
          }
        }

        if(item.products_color_waist_id) {
          prod = item.products_color_waist 
          if(name) {
            return name + ' (' + prod.color.name + ' - ' + prod.waist.name + ')'
          } else {
            return prod.name + ' (' + prod.color.name + ' - ' + prod.waist.name + ')'
          }          
        }

        if(item.products_compound_id) {
          prod = item.products_compound 
          if(name) {
            return name
          } else {
            return prod.name
          }   
        }        
      },

      // PRINT
      sendPrinter(item) {        
        this.crud.print.key=Math.random()
        this.crud.print.id=item.id
        this.crud.print.reference = item.reportDefault
      },      

      // INVOICE
      generateInvoice(item) {
        this.$router.push({ name: 'SalesStaffSalesGenerateByRemits', params: {remitsID: item.id} })
      }
    }
  }
</script>
<style>
  .crud-remits-title {
    font-size: 15px;
    font-weight: 600;
  }
  .crud-remits-code {
    color: gray;
    font-size: 12px;
  }   
  #details_items {
    position: fixed;
    right: 15px;    
  }     
  .table-remits-sub {
    overflow: auto;
    max-height: 350px;
  }
  .crud-remits-title-table-custom {
    color: #fff;
    background-color: #2f353a;
    border-color: #40484f;
    font-weight: 700;
    padding-right: 10px;
    width: 30%;
    text-align: right
  }
  .crud-remits-value-table-custom {
    padding-left: 5px;
    white-space: pre-line;
  }  
  .table-full-detail-remits {
    overflow: auto;
    max-height: 350px;    
  }  
  .crud-remits-totales-items-header {
    background: var(--dark);
    color: #fff;
    font-size: 12px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;    
    text-align: right;
    font-weight: 700;
  }
  .crud-remits-counts-items {
    font-size: 12px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: solid;
    border-width: 1px;    
  }  
  .crud-remits-totales-items {
    font-size: 12px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: dotted;
    border-width: 1px;    
  }    
  .crud-remits-wizard .wizard-header {
    padding: 0px;
  }  
  .crud-remit-item-input {
    height: 25px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
  }
  .crud-remit-table-items {
    height: 350px;
    overflow: auto;
  }
</style>
<style scoped>
  .b-avatar {
    width: 25px;
    height: 25px;    
  }  
</style>